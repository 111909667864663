import React, { useEffect, useState, useCallback } from 'react';
import { datadogRum  } from '@datadog/browser-rum';
import { useAuth0 } from '@auth0/auth0-react';
import { useOutletContext } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import LinearProgress from '@mui/material/LinearProgress';
import APIKeyListItem from './User/APIKeyListItem';
import AddAPIKeyListItem from './User/AddAPIKeyListItem';
import OrganizationDetails from './User/OrganizationDetails';
import UserDetails from './User/UserDetails';
import ProfileEditButtonGroup from './User/ProfileEditButtonGroup';
import KeynomeCloudStatusDetails from './User/KeynomeCloudStatusDetails';
import KeynomeManagerDownload from './User/KeynomeManagerDownload';

const styles = {
  container: {
    m: 'auto',
    mt: 1,
    paddingRight: '30px',
    paddingBottom: '30px'
  },
  sectionHeader: {
    backgroundColor: '#eee',
    padding: '1px 10px'
  },
  profileHeader: {
    backgroundColor: '#eee',
    padding: '1px 0px 1px 10px'
  },
  apiKeysList: {
    width: '100%',
    bgcolor: 'background.paper',
    paddingTop: '0',
    paddingBottom: '0'
  }
};

function Profile(props) {
    const {
        userData, 
        setUserData,
        organization, 
        openTermsDialog
    } = useOutletContext();

    const { getAccessTokenSilently } = useAuth0();

    const [organizationAttributesWithUsage, setOrganizationAttributesWithUsage] = useState(null);
    const [userAttributesWithEmail, setUserAttributesWithEmail] = useState(null);
    const [editingProfile, setEditingProfile] = useState(false);
    const [savingProfile, setSavingProfile] = useState(false);
    const [passwordResetRequested, setPasswordResetRequested] = useState(false);
    const [passwordResetSent, setPasswordResetSent] = useState(false)
    const [name, setName] = useState(userData?.data.attributes.name)
    const [email, setEmail] = useState(null)
    const [apiKeys, setApiKeys] = useState(null)
    const [loadAPIKeys, setLoadAPIKeys] = useState(true);

    const deleteAPIKey = useCallback((apiKeyId) => {
      getAccessTokenSilently()
        .then(accessToken => {
          const headers = new Headers({
            Authorization: `Bearer ${accessToken}`
          });

          return fetch(
            `${process.env.REACT_APP_KEYNOME_API_URL_BASE}/v1/api_keys/${apiKeyId}`,
            {
              method: 'DELETE',
              headers,
            },
          )
        })
        .then((res) => {
          if (!res.ok) {
            throw new Error(`Request to ${res.url} failed with ${res.status} (${res.statusText})`)
          }

          setLoadAPIKeys(true);
        })
        .catch(datadogRum.addError)
    }, [getAccessTokenSilently])

    useEffect(() => {
      if (!organization || !loadAPIKeys) {
        return;
      }

      setLoadAPIKeys(false);

      getAccessTokenSilently()
        .then(accessToken => {
          const headers = new Headers({
            Authorization: `Bearer ${accessToken}`
          });

          return fetch(
            `${process.env.REACT_APP_KEYNOME_API_URL_BASE}/v1/organizations/${organization.id}/api_keys`,
            {
              method: 'GET',
              headers,
            },
          )
        })
        .then((res) => {
          if (!res.ok) {
            throw new Error(`Request to ${res.url} failed with ${res.status} (${res.statusText})`)
          }

          return res.json()
        })
        .then((response) => {
          setApiKeys(response["data"])
        })
        .catch(datadogRum.addError)
    }, [loadAPIKeys, organization, getAccessTokenSilently])

    const changePassword = useCallback(() => {
      setPasswordResetRequested(true)
      getAccessTokenSilently()
        .then(accessToken => {
          const headers = new Headers({
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
          });

          return fetch(
            // TODO: set URL and Client ID as env variable
            `https://dayzerodiagnostics.us.auth0.com/dbconnections/change_password`,
            {
              method: 'POST',
              headers,
              body: JSON.stringify(
                {
                    client_id: "0r5DKIPfYO0iky7cqBCK8Y7sgBycEdC9",
                    email: userAttributesWithEmail.email,
                    connection: "Username-Password-Authentication"
                }
            )
            },
          )
        })
        .then((res) => {
          if (!res.ok) {
            throw new Error(`Request to ${res.url} failed with ${res.status} (${res.statusText})`)
          }
          setPasswordResetSent(true);
        })
        .catch(datadogRum.addError);

    }, [getAccessTokenSilently, userAttributesWithEmail])

    const saveProfileEdit = useCallback(() => {
      setSavingProfile(true)

      getAccessTokenSilently()
        .then(accessToken => {
          const headers = new Headers({
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
          });

          let attributes = {}
          if (name !== userData.data.attributes.name) {
            attributes["name"] = name
          }

          if (email !== userAttributesWithEmail.email) {
            attributes["email"] = email
          }

          return fetch(
            `${process.env.REACT_APP_KEYNOME_API_URL_BASE}/v1/users/${userData.data.id}`,
            {
              method: 'PUT',
              headers,
              body: JSON.stringify(
                {
                    data: {
                        type: "user",
                        attributes: attributes
                    }

                }
            )
            },
          )
        })
        .then((res) => {
          if (!res.ok) {
            throw new Error(`Request to ${res.url} failed with ${res.status} (${res.statusText})`);
          }
          return res.json();
        })
        .then((response) => {
          setUserData(response)
          setSavingProfile(false)
          setEditingProfile(false)
        })
        .catch(datadogRum.addError);

    }, [name, email, editingProfile]) // eslint-disable-line react-hooks/exhaustive-deps

    const cancelProfileEdit = useCallback(() => {
      setName(userData.data.attributes.name)
      setEmail(userAttributesWithEmail.email)
      setEditingProfile(false)
    }, [editingProfile]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
      if (!userData || editingProfile) {
        return
      }

      setName(userData.data.attributes.name)
    }, [userData, editingProfile])

    useEffect(() => {
      if (!userAttributesWithEmail || editingProfile) {
        return
      }

      setEmail(userAttributesWithEmail.email)
    }, [userAttributesWithEmail, editingProfile])

    useEffect(() => {
      if (!userData) {
        return
      }

      getAccessTokenSilently()
        .then(accessToken => {
          const headers = new Headers({
            Authorization: `Bearer ${accessToken}`,
          });

          return fetch(
            `${process.env.REACT_APP_KEYNOME_API_URL_BASE}/v1/users/${userData.data.id}?extra_fields=email`,
            {
              method: 'GET',
              headers,
            },
          )
        })
        .then((res) => {
          if (!res.ok) {
            throw new Error(`Request to ${res.url} failed with ${res.status} (${res.statusText})`);
          }
          return res.json();
        })
        .then((response) => setUserAttributesWithEmail(response.data.attributes))
        .catch(datadogRum.addError);
    }, [getAccessTokenSilently, userData])

    useEffect(() => {
      if (!userData) {
        return
      }

      getAccessTokenSilently()
        .then(accessToken => {
          const headers = new Headers({
            Authorization: `Bearer ${accessToken}`,
          });

          return fetch(
            `${process.env.REACT_APP_KEYNOME_API_URL_BASE}/v1/organizations/${organization.id}?extra_fields=users_ct,users_ct_limit,uploaded_gigabases_current_month,uploaded_gigabases_current_month_limit`,
            {
              method: 'GET',
              headers,
            },
          )
        })
        .then((res) => {
          if (!res.ok) {
            throw new Error(`Request to ${res.url} failed with ${res.status} (${res.statusText})`);
          }
          return res.json();
        })
        .then((response) => setOrganizationAttributesWithUsage(response.data.attributes))
        .catch(datadogRum.addError);
    }, [getAccessTokenSilently, organization, userData]);

    const canSaveProfile = (editingProfile && ((name !== userData.data.attributes.name) || (email !== userAttributesWithEmail.email)))
    const nameIsValid = editingProfile && new RegExp(/^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžæÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]{1,75}$/u).test(name)
    const emailIsValid = editingProfile && new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i).test(email)

    let apiKeysList = <LinearProgress />;
    if (apiKeys != null) {
      apiKeysList = apiKeys.map((apiKey, idx) => (
        <APIKeyListItem apiKey={apiKey} deleteAPIKey={deleteAPIKey} idx={idx} />
      ));
    }

    return (
      <Grid container maxWidth="lg" spacing={2} sx={styles.container}>
      <Grid item xs={12} sm={6}>
        <Paper elevation={2}>
          <Box sx={styles.sectionHeader}>
            <p><b>ORGANIZATION</b></p>
          </Box>
          <OrganizationDetails 
            organization={organization}
            organizationAttributesWithUsage={organizationAttributesWithUsage}
          />
        </Paper>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Paper elevation={2}>
          <Box sx={styles.profileHeader}>
            <Grid container>
              <Grid item xs={6}>
                <p><b>PROFILE</b></p>
              </Grid>
              <ProfileEditButtonGroup
                editingProfile={editingProfile}
                userData={userData}
                userAttributesWithEmail={userAttributesWithEmail}
                canSaveProfile={canSaveProfile}
                savingProfile={savingProfile}
                nameIsValid={nameIsValid}
                emailIsValid={emailIsValid}
                setEditingProfile={setEditingProfile}
                saveProfileEdit={saveProfileEdit}
                cancelProfileEdit={cancelProfileEdit}
              />
            </Grid>
          </Box>
          <UserDetails
              editingProfile={editingProfile}
              name={name}
              setName={setName}
              nameIsValid={nameIsValid}
              email={email}
              setEmail={setEmail}
              emailIsValid={emailIsValid}
              userData={userData}
              userAttributesWithEmail={userAttributesWithEmail}
              openTermsDialog={openTermsDialog}
              passwordResetSent={passwordResetSent}
              passwordResetRequested={passwordResetRequested}
              changePassword={changePassword}
            />
        </Paper>
      </Grid>
      <Grid item xs={12}>
          <Paper elevation={2}>
            <Box sx={styles.profileHeader}>
              <p><b>Keynome Manager Clients</b></p>
          </Box>
          <Box>
              <List sx={styles.apiKeysList}>
                {apiKeysList}
                <AddAPIKeyListItem setLoadAPIKeys={setLoadAPIKeys} organization={organization} canAddKey={apiKeys !== null} />
              </List>
            </Box>
          </Paper>
      </Grid>
      <Grid item xs={12} sm={7}>
            <Paper elevation={2}>
              <KeynomeManagerDownload /> 
            </Paper>
      </Grid>
      <Grid item xs={12} sm={5}>
        <Paper elevation={2}>
          <Box sx={styles.profileHeader}>
              <p><b>Keynome Cloud Status</b></p>
          </Box>
          <Box>
            <KeynomeCloudStatusDetails region={process.env.REACT_APP_KEYNOME_API_URL_BASE.startsWith("https://eu.api.keynome") ? 'eu' : 'us'} />
          </Box>
        </Paper>
      </Grid>
    </Grid>
    );
}

export default Profile;
