import { useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';


const styles = {
  container: {
    p: 2,
  },
  metaInfo: {
    mb: 2,
  },
  divider: {
    my: 2,
  },
  statusIndicator: {
    display: 'inline-block',
    width: 8,
    height: 8,
    borderRadius: '50%',
    marginRight: 1,
  },
  operational: {
    backgroundColor: '#4caf50',
  },
  degraded: {
    backgroundColor: '#ff9800',
  },
  outage: {
    backgroundColor: '#f44336',
  },
  timestamp: {
    color: 'text.secondary',
    fontSize: 'small',
    display: 'block',
  }
};

function KeynomeCloudStatusDetails({ region }) {
    const [apiStatus, setApiStatus] = useState(null);
    const [apiStatusCheckedAt, setApiStatusCheckedAt] = useState(null);

  const getStatusColor = (status) => {
    switch (status?.toLowerCase()) {
      case 'operational':
        return styles.operational;
      case 'degraded':
        return styles.degraded;
      case 'outage':
        return styles.outage;
      default:
        return styles.operational;
    }
  };

  useEffect(() => {
    const checkApiStatus = async () => {
      try {
        await fetch(process.env.REACT_APP_KEYNOME_API_URL_BASE);
        setApiStatus('Available');
      } catch (error) {
        setApiStatus('Degraded');
      } finally {
        setApiStatusCheckedAt(new Date().toLocaleString());
      }
    };

    checkApiStatus();
  }, [region]);

  return (
    <Box sx={styles.container}>
      <Stack spacing={2}>
      <Box>
          <Typography>
            <Typography component="h6" color="text.secondary">
              {process.env.REACT_APP_KEYNOME_API_URL_BASE}
            </Typography>
            <Box 
              component="span" 
              sx={{ ...styles.statusIndicator, ...getStatusColor(apiStatus) }} 
            />
            {' '}
            {apiStatus}
            {' '}
            <Typography component="span" sx={styles.timestamp}>
              (last ping: {apiStatusCheckedAt})
            </Typography>
          </Typography>
        </Box>

        <Box>
          <Typography>
            <Box component="span" fontWeight="bold">Current Stable Release:</Box>{' '}
            v2024.10.23.a
          </Typography>
          <Box sx={{ ml: 2, mt: 1 }}>
            <Typography>
              Keynome ID: v3.0{' '}
              <Typography component="span" sx={styles.timestamp}>
                (MicroRef v2.2.1)
              </Typography>
            </Typography>
            <Typography sx={{ mt: 0.5 }}>
              Keynome gAST: v0.7.6.0{' '}
              <Typography component="span" sx={styles.timestamp}>
                (MicrohmDB v0.13.0 / CLSI M100 Ed34 [2024])
              </Typography>
            </Typography>
          </Box>
        </Box>
      </Stack>

    </Box>
  );
}

export default KeynomeCloudStatusDetails;