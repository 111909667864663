import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Typography, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

function APIKeyListItem(props) {
  const {
    apiKey,
    deleteAPIKey
  } = props;

  const [deletingKey, setDeletingKey] = useState(false)

  const getKeyStatus = () => {
    const createdAt = new Date(apiKey.attributes.created_at);
    const now = new Date();
    const diffMinutes = (now - createdAt) / (1000 * 60);
    
    if (diffMinutes < 5) {
      return { status: "Not Activated", color: "#1976d2" }; // blue
    } else if (diffMinutes < 60) {
      return { status: "Active", color: "#4caf50" }; // green
    } else {
      return { status: "Idle", color: "#ff9800" }; // orange
    }
  };

  const getTimeDiff = () => {
    const createdAt = new Date(apiKey.attributes.created_at);
    const now = new Date();
    const diffMinutes = Math.floor((now - createdAt) / (1000 * 60));
    
    if (diffMinutes < 60) {
      return `${diffMinutes} minutes`;
    } else if (diffMinutes < 1440) { // less than 24 hours
      const hours = Math.floor(diffMinutes / 60);
      return `${hours} ${hours === 1 ? 'hour' : 'hours'}`;
    } else {
      const days = Math.floor(diffMinutes / 1440);
      return `${days} ${days === 1 ? 'day' : 'days'}`;
    }
  };

  return (
    <Box>
      <Grid container>
        <Grid item xs={3} sx={{padding: "13px 20px"}}>
          <Typography sx={{textAlign: "left"}}><b>{apiKey.attributes.name}</b></Typography>
        </Grid>
        <Grid item xs={5} sx={{padding: "13px 20px"}}>
          <Typography>Client ID: <pre style={{display: "inline", backgroundColor: "#eee", padding: "5px 10px", borderRadius: "5px"}}>{apiKey.attributes.auth0_client_id}</pre></Typography>
        </Grid>
        <Grid item xs={3} sx={{padding: "13px 20px"}}>
        <Typography>
            <Box
              component="span"
              sx={{
                display: 'inline-block',
                width: 8,
                height: 8,
                borderRadius: '50%',
                marginRight: 1,
                backgroundColor: getKeyStatus().color,
              }}
            />
            {getKeyStatus().status}
            <Typography 
              component="span" 
              sx={{ 
                color: 'text.secondary',
                fontSize: 'small',
                marginLeft: 1
              }}
            >
              (last seen: {getTimeDiff()} ago)
            </Typography>
          </Typography>
        </Grid>
        <Grid item xs={1} sx={{textAlign: "right", padding: "5px 20px"}}>
        <IconButton
          disabled={deletingKey}
            sx={{color: "red"}} 
            aria-label="delete" 
            onClick={() => {
              setDeletingKey(true);
              deleteAPIKey(apiKey.id)
            }}
        >
          <DeleteIcon />
        </IconButton>
        </Grid>
        </Grid>
    </Box>
  );
}

export default APIKeyListItem;
